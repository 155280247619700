<template>
	<div class="container">
		<Header  :flag="flag"/>	
		<div class="banner">
			 <div class="banner-content">
				 <div class="banner-title">
					 <span class="banner-sub-title">蓝莓科技面向全国“诚招代理，广纳贤才”</span>
					 <br/>
					 <span class="banner-sub-title1">千亿级市场期待与您共同开拓，无限量空间任凭您自由发挥</span>
					 <br/>
					 <span class="proxy-btn" >
						 <a href="#apply" class="btn-text">申请加入</a>
					 </span>
				 </div>
			 </div>
		</div>
		<div class="main-top">
			<div class="icon-div">
				<img src="../assets/img/IC_MH.png" />
			</div>
			<div class="top">
				<span class="top-title">蓝莓科技——让天下没有难做的销售</span>
				<br/>
				<span class="top-subtitle">我们为用户提供从获客、触达、跟进、转化到客户裂变的全流程智能销售解决方案</span>
			</div>
			<div class="buttom">
				——蓝莓科技
			</div>
		</div>
		<div class="main-middle">
			<div class="liuc">
				<img src="../assets/img/YTK_BG.png" />
			</div>
		</div>
		<div class="main-middle1">
			<div class="adv-title">
				用数据驱动销售，用智能节省成本
			</div>
			<div class="adv">
				<div class="adv-content">
				  <div class="box" v-for="item in list">
					  <div class="title">
						  <span class="st">{{item.subTitle}}</span><br/><span class="sub-title">{{item.title}}</span>
					  </div>
					  <br/>
					  <span class="intro">{{item.intro}}</span>
				  </div>
				</div>
			</div>
			<div class="market">
				<div class="market-title">
					市场规模达千亿<br/>潜力无可估量
				</div>
				<div class="market-content">
					<div class="market-content-top">
						 <div class="box1" >
							 <div class="box-content">
								  <span class="title">市场规模巨大</span>
								  <br/>
								  <span class="sub-title">
									  <span class="st">5000万+</span>  中小企业，
									  <br/>
									  <span class="st">9000万+</span>个体户，
									  <br/>销售人员超过千万
								  </span>
							 </div>
						 </div>
						 <div class="box2" >
							 <div class="box-content">
								  <span class="title">市场获客需求大</span>
								  <br/>
								  <span class="sub-title">
									  <span class="st">2000千亿</span>  
									  规模获客市场
								  </span>
							 </div>
							  <span class="title"></span>
							  <br/>
						 </div>
						 <div class="box3" ></div>
					</div>
				    <div class="market-content-mid">
					  <div class="box" >
						  <div class="box-content">
							  <span class="title">巨大的销售交易规模</span>
							  <br/>
							  <span class="sub-title">预计2027年销售交易规模,将超过16万亿元人民币</span>
						  </div>
						  
					  </div>
				  </div>
				  
				</div>
			</div>
		</div>
		<div class="advantage">
			<div class="adva-title">
				我们为合作伙伴提供全方位支持<br/>让您无后顾之忧
			</div>
			<div class="adva-cont">
				<div class="box" v-for="item in list1">
					<span class="box-t">{{item.title}}</span>
					<br/>
					<span class="box-c">{{item.intro}}</span>
				</div>
			</div>
		</div>
		<div class="cooperate">
			<div class="cooperate-container">
				<div class="title">
					<span class="cooperate-title">合作流程</span>	
				</div>
				<div class="content" >
					<div class="box" v-for="item in cooperationList">
						<div class="title">
							<span class="title-t">{{item.title}}</span>
						</div>
						<div class="intro" >
							{{item.intro}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="apply" id="apply">
			<div class="context">
				<div class="title">全国招商</div>
				<div class="content">
					<el-form label-position="right" :rules="rules" label-width="160px"  :model="applyForm" ref="applyForm">
					  <el-form-item class="label" label="姓名" prop="name" required>
					    <el-input v-model="applyForm.name"></el-input>
					  </el-form-item>
					  <el-form-item class="label" label="电话号码" prop="phone" required>
					    <el-input v-model="applyForm.phone"></el-input>
					  </el-form-item>
					  <el-form-item class="label" label="公司名称" prop="companyName">
					    <el-input v-model="applyForm.companyName"></el-input>
					  </el-form-item>
					</el-form>
					<el-button type="danger" class="sub_btn" size="medium" @click="onSubmit('applyForm')">提交申请</el-button>
					<span class="phone">或者拨打招商热线：{{telphone}}</span>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
	import Header from '@/components/Header.vue'
	import Footer from '@/components/Footer.vue'
	import qs from 'qs';
	export default {
		metaInfo: {
			title: '蓝莓科技-招商加盟',
			meta: [
			  {
				name: 'keywords',
				content: '招商加盟'
			  },
			  {
				name: 'description',
				content: '蓝莓科技(www.lanmeihulian.com)易拓客是国内专业的智能销售服务提供商。我们为企业和个人销售提供从线索挖掘、商机触达、客户管理到成单分析的全流程智能销售解决方案，帮助大中小型企业和个人在销售端降低销售成本，提升销售业绩'
			  }
			]
		},
		name: 'Agent',
		components: {
			Header,
			Footer
		},	
		data(){
			return{
				flag:'us',
				list:[
					{
						subTitle:"亿级",
						title:"客源数据",
						intro:"实时动态更新，基本覆盖全国客源，甄别客源，获取精准客源"
					},
					{
						title:"触达效率提升",
						subTitle:"50%",
						intro:"高效触达方便快捷"
					},
					{
						title:"成本降低",
						subTitle:"50%",
						intro:"改进销售模式，数据分析精准客源，信息分析成单客户"
					},
					{
						title:"促进销售转化率提升",
						subTitle:"10%",
						subTitle1:"",
						intro:"全新营销手段模式，促进销售流程管理"
					}
				],
				list1:[
					{
						title:"获客成本低",
						intro:"甄别客源精准客户，提高工作效率提升工作质量，减少获客成本。"
					},
					{
						title:"销售利润高",
						intro:"销售平台多方多维，提供全套销售服务，跟进销售流程确保销售质量"
					},
					{
						title:"营销模式强",
						intro:"完整营销模式配合，提供完善营销模式，让销售稳步提升。"
					},
					{
						title:"合作低风险",
						intro:"企业合作安全保障，确保合作质量提高合作占率。"
					},
					{
						title:"培训方式新",
						intro:"培训方式优质新颖，提供全新培训保证培训质量。"
					},
					{
						title:"服务支持全",
						intro:"支持全程全方服务，提供服务技术支撑保证服务质量，提高服务效率。"
					}
				],
				cooperationList:[
					{
						title: "签合同",
						intro: "双方就合作系统拟合同，确定无误之后双方签订合同，确定合作关系。"
					},
					{
						title: "付款",
						intro: "合同签订之后，代理商给总部按时打款。"
					},
					{
						title: "培训",
						intro: "打完款后，总部发放所有资料，包括后台账号密码、素材等电子扶持材料，线上培训代理商有关项目开展的细节。"
					}
				],
			    applyForm: {
				  name: '',
				  phone: '',
				  companyName: ''
				},
				rules: {
				  name: [
					{ required: true, message: '请输入姓名', trigger: 'blur' },
					{ min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
				  ],
				  phone: [
					{ required: true, message: '请输入手机号码', trigger: 'change' },
					{ min: 11, max: 11, message: '请输入正确的手机号码', trigger: 'blur' }
				  ],
				},
				telphone: ""
			}
		},
		created() {
			this.getDetail();
		},
		methods:{
			async getDetail(){
			   await this.$http.getCompanyParam().then(res => {
					if(res.data!=null){
						this.telphone = res.data.phone;
					}
				})
			},
			onSubmit(formName){
				this.$refs[formName].validate((valid) => {
				  if (valid) {
					  let data={
						  name:this.applyForm.name,
						  phone:this.applyForm.phone,
						  companyName:this.applyForm.companyName,
						  };
					 Object.keys(data).forEach(item => {
					     if (typeof data[item] === 'undefined' || data[item] === null || data[item] === '') {
					         delete data[item];
					     }
					 });
					  this.$http.addAgent(data).then(res => {
					  	if(res.code==0){
							this.$refs[formName].resetFields();
							this.$message({
							  message: '提交成功',
							  type: 'success',
							  duration: 1000
							});
					  	}
					  });
				  } else {
					console.log('error submit!!');
					return false;
				  }
				});
			}
		}
	}	
</script>

<style scoped lang="less">
.banner{
	 padding-top: 60px;
	 height: 480px;
	 background-image: url(https://file.lanmeihulian.com/zs_banner.png);
	 background-repeat: no-repeat;
	 background-size:100% 100%;
	 .banner-content{
		 width: 1200px;
		 margin: auto;
		 .banner-title{
			 text-align: center;
			 .banner-sub-title{
				 display: inline-block;
				 margin-top: 60px;
				 font-size: 46px;
				 font-weight: 600;
				 color: white;
			 }
			 .banner-sub-title1{
				display: inline-block;
				margin-top: 20px;
				font-size: 32px;
				color: white; 
			 }
			 .proxy-btn{
				margin-top: 40px;
				display: inline-block;
				width: 300px;
				height: 80px;
				line-height: 80px;
				color: #217bfb;
				background-color: #ffffff;
				border-radius: 5px;
				.btn-text{
					font-size: 24px;
					font-weight: 600;
				}
			 }
		 }
		 
	 }
}	
.main-top{
	background-color: #f6f6f6;
	height:auto;
	.icon-div{
	   margin: auto;
	   padding-top: 32px;
	   width: 1200px;
	}
	.top{
		margin: auto;
		margin-top: 32px;
		
		width: 1200px;
		height:130px;
		text-align: left;
		.top-title{
			font-size: 38px;
			line-height: 80px;
			font-weight: 600;
			color: #333333;
		}
		.top-subtitle{
			font-size: 28px;
			color: #666666;
		}
	}
	.buttom{
		margin: auto;
		margin-top: 32px;
		padding-bottom: 65px;
		width: 1200px;
		height:40px;
		text-align: right;
		font-size: 28px;
		color: #666666;
	}	
}
.main-middle{
	.liuc{
		width: 1200px;
		height: 659px;
	}
}
.main-middle1{
	height: auto;
	background-image: url(../assets/img/zs_img2.png);
	background-repeat: no-repeat;
	background-color: #f6f6f6;
	.adv-title{
		text-align: center;
		font-size: 48px;
		line-height: 80px;
		font-weight: 600;
		padding-top: 84px;
		color: #ffffff;
	}
	.adv{
		margin: auto;
		margin-top: 70px;
		width: 1200px;
		height: 400px;
		.adv-content{
			display: flex;
			display: -webkit-flex;
			flex-direction: row;
			justify-content: space-between;
			.box{
				width: 260px;
				height: 280px;
				padding-top: 30px;
				text-align: center;
				background-color: white;
				.title{
					width: 200px;
					height: 130px;
					margin: auto;
					border-bottom: 1px #E6E6E6 solid;
					.st{
					  font-size: 38px;
					  color: #128BFB;
					  line-height: 60px;
					  font-weight: 600;
					}
					.sub-title{
					 font-size: 22px;
					 line-height: 40px;
					 color: #128BFB;
					}	
				}
				.intro{
					display: inline-block;
					width: 200px;
					height: 130px;
					margin: auto;
					padding-top: 20px;
					font-size: 18px;
					line-height: 25px;
					text-align: left;
				}	
			}
		}
	}
	.market{
		width: 1200px;
		margin: auto;
		margin-top: 40px;
		height: auto;
		.market-title{
			height: 160px;
			width: 380px;
			background-image: url(../assets/img/FONT_LATENTCAPACITY.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			text-align: left;
			font-size: 38px;
			line-height: 80px;
			font-weight: 600;
			color: #131313;
		}
		.market-content{
			margin-top: 47px;
			padding-bottom: 65px;
			.market-content-top{
				width: 1200px;
				display: flex;
				display: -webkit-flex;
				flex-direction: row;
				justify-content: space-between;
				.box1,.box2{
					width: 500px;
					height: 314px;
					background-color: white;
				}
				.box1{
					.box-content{
						width: 460px;
						margin-top: 40px;
						margin-left: 36px;
						background-image: url(../assets/img/zs_01.png);
						background-repeat: no-repeat;
						padding-left: 70px;
						.title{
							display: inline-block;
							padding-top: 5px;
							padding-left: 40px;
							font-size: 28px;
							line-height: 60px;
							font-weight: 600;
							color: #128BFB;
						}	
						
						.sub-title{
							display: inline-block;
							padding-top: 24px;
							padding-left: 40px;
							font-size: 24px;
							line-height: 50px;
							color: #131313;
							.st{
							   padding-right:10px;
							   color: #128BFB;
							}
						}	
					}
				}
				.box2{
					.box-content{
						width: 460px;
						margin-top: 40px;
						margin-left: 36px;
						background-image: url(../assets/img/zs_02.png);
						background-repeat: no-repeat;
						padding-left: 70px;
						.title{
							display: inline-block;
							padding-top: 5px;
							padding-left: 40px;
							font-size: 28px;
							line-height: 60px;
							font-weight: 600;
							color: #128BFB;
						}	
						
						.sub-title{
							display: inline-block;
							padding-top: 24px;
							padding-left: 40px;
							font-size: 24px;
							line-height: 50px;
							color: #131313;
							.st{
							   padding-right:10px;
							   color: #128BFB;
							}
						}	
					}
				}
				.box3{
					width: 140px;
					height: 314px;
					background-image: url(../assets/img/ZS_BG.png);
					background-repeat: no-repeat;
				}
			}
			.market-content-mid{
				width: 1200px;
				margin-top: 20px;
				
				height: 254px;
				background-color: white;
				.box{
					padding-top: 40px;
					margin-left: 36px;
					.box-content{
						background-image: url(../assets/img/zs_03.png);
						background-repeat: no-repeat;
						padding-left: 70px;
						.title{
							display: inline-block;
							padding-top: 5px;
							padding-left: 40px;
							font-size: 28px;
							line-height: 60px;
							font-weight: 600;
							color: #128BFB;
						}	
						.sub-title{
							display: inline-block;
							padding-top: 24px;
							padding-left: 40px;
							font-size: 24px;
							line-height: 50px;
							color: #131313;
						}	
					}
				}
			}
		}
	}
}
.advantage{
	width: 1200px;
	margin: auto;
	margin-top: 54px;
	height: 750px;
	.adva-title{
		height: 160px;
		width: 600px;
		background-image: url(../assets/img/FONT_OURSUPPORT.png);
		background-repeat: no-repeat;
		background-size: 60% 80%;
		text-align: left;
		font-size: 38px;
		line-height: 60px;
		font-weight: 600;
	}
	.adva-cont{
		margin-top: 44px;
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		.box{
			width: 380px;
			height: 230px;
			background-color: #f6f6f6;
			border-radius: 0px 15px 15px 15px;
			box-shadow: 5px 5px 5px gray;
			margin-top: 20px;
			.box-t{
				display: inline-block;
				margin-left: 35px;
				width: 300px;
				text-align: left;
				font-size: 24px;
				line-height: 100px;
				font-weight: 600;
				color: #217bfb;
			}
			.box-c{
				display: inline-block;
				margin-left: 35px;
				width: 300px;
				text-align: left;
				font-size: 20px;
				line-height: 35px;
				color: #555555;
			}
		}
	}
}
.cooperate{
	background-color: white;
	padding-top: 40px;
	.cooperate-container{
		width: 1200px;
		margin: auto;
		margin-top: 20px;
		height:750px;
		.title{
			height: 130px;
			width: 600px;
			background-image: url(../assets/img/FONT_COOPERATIONPROCESS.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			text-align: left;
			.cooperate-title{
				display: inline-block;
				padding-top: 40px;
				font-size: 42px;
				line-height: 60px;
				font-weight: 600;
				color: #131313;
			}
		}
		.content{
		margin-top: 46px;
		display: flex;
		display: -webkit-flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		.box{
			width: 360px;
			height: 420px;
			text-align: center;
			margin: 20px;
			.title{
				width: 300px;
				height: 300px;
				margin: auto;
				background-image: url('../assets/img/zs_qx.png');
				background-repeat: no-repeat;
				.title-t{
					display: table-cell;
					vertical-align: middle;
					width: 300px;
					height: 300px;
					font-size: 30px;
					font-weight: 600;
					text-align: center;
					color: #217bfb;
				}
			}
			.intro{
				width: 300px;
				margin: auto;
				margin-top: 30px;
				font-size: 20px;
				line-height: 35px;
				color: #131313;
				text-align: left;
			}
		}
	}
	}
}
.main{
	width: 1200px;
	margin: auto;
}	
.apply{
	height: 570px;
	background-image: url(../assets/img/ZS_BF.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	.context{
		width: 1200px;
		margin: auto;
		padding-top: 40px;
		.title{
			width: 800px;
			margin: auto;
			height: 100px;
			text-align: left;
			font-size: 38px;
			font-weight: 600;
			color: white;
		}
		.content{
			width: 800px;
			margin: auto;
			.label{
				color: white;
				/deep/.el-form-item__label{
					font-size: 22px;
				}
			}
			/deep/.el-form-item{
				height: 60px;
			}
			.el-input /deep/ .el-input__inner {
				 font-size:20px;
			}
			.sub_btn{
				margin-left: 160px;
				width: 200px;
				height: 80px;
				background-color: white;
				color: #217bfb;
				font-size: 26px;
				font-weight: 600;
				border-radius: 10px;
			}
			.phone{
				padding-left: 20px;
				color: white;
				font-size: 26px;
				font-weight: 600;
			}
		}
	}	
}	
</style>
